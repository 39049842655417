import { createTheme } from "@mui/material/styles"

const colors = {
  white: "#fff",
  black: "#000",
  primaryDark: "#1565c0",
  primaryLight: "#42a5f5",
  primaryMain: "#002d72",//"#3f51b5",
  backgroundLight: "#fafafa",
  backgroundDark: "#303030",
  paperLight: "#fff",
  paperDark: "#424242",
  dividerLight: "rgba(0, 0, 0, 0.12)",
  dividerDark: "rgba(255, 255, 255, 0.12)",
  textPrimaryLight: "rgba(0, 0, 0, 0.87)",
  textPrimaryDark: "#fff",
  textSecondaryLight: "rgba(0, 0, 0, 0.54)",
  textSecondaryDark: "rgba(255, 255, 255, 0.7)",
  textDisabledLight: "rgba(0, 0, 0, 0.38)",
  textDisabledDark: "rgba(255, 255, 255, 0.5)",
  linkLight: "#fff",
  linkDark: "#3f51b5",
  actionActiveLight: "rgba(0, 0, 0, 0.54)",
  actionActiveDark: "#fff",
  actionHoverLight: "rgba(0, 0, 0, 0.04)",
  actionHoverDark: "rgba(255, 255, 255, 0.08)",
  actionSelectedLight: "rgba(0, 0, 0, 0.08)",
  actionSelectedDark: "rgba(255, 255, 255, 0.16)",
  actionDisabledLight: "rgba(0, 0, 0, 0.26)",
  actionDisabledDark: "rgba(255, 255, 255, 0.3)",
  actionDisabledBackgroundLight: "rgba(0, 0, 0, 0.12)",
  actionDisabledBackgroundDark: "rgba(255, 255, 255, 0.12)",
}

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      contrastText: colors.white,
      dark: colors.primaryDark,
      light: colors.primaryLight,
      main: colors.primaryMain,
    },
   breakpoints: {
      values: {
         xs: 0,
         sm: 600,
         md: 890,
         lg: 1200,
         xl: 1536,
       },
     },
    background: {
      default: mode === 'light' ? colors.backgroundLight : colors.backgroundDark,
      paper: mode === 'light' ? colors.paperLight : colors.paperDark,
     },
    divider: mode === 'light' ? colors.dividerLight : colors.dividerDark,
    text: {
      primary: mode === 'light' ? colors.textPrimaryLight : colors.textPrimaryDark,
      secondary: mode === 'light' ? colors.textSecondaryLight : colors.textSecondaryDark,
      disabled: mode === 'light' ? colors.textDisabledLight : colors.textDisabledDark,
      link: mode === 'light' ? colors.linkLight : colors.linkDark,
    },
    action: {
      active: mode === 'light' ? colors.actionActiveLight : colors.actionActiveDark,
      hover: mode === 'light' ? colors.actionHoverLight : colors.actionHoverDark,
      selected: mode === 'light' ? colors.actionSelectedLight : colors.actionSelectedDark,
      disabled: mode === 'light' ? colors.actionDisabledLight : colors.actionDisabledDark,
      disabledBackground: mode === 'light' ? colors.actionDisabledBackgroundLight : colors.actionDisabledBackgroundDark,
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
})

export const lightTheme = createTheme(getDesignTokens("light"))
export const darkTheme = createTheme(getDesignTokens("dark"))
