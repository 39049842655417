import React, { useRef, useState } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./pages/Home"
import About from "./pages/About"
import Contact from "./pages/Contact"
import { lightTheme, darkTheme } from "./theme/theme"
import { HelmetProvider } from "react-helmet-async"
import CustomNav from "./components/CustomNav"
import GetSingleImageDim from "./components/GetSingleImageDim"
import PageLayout from "./pages/PageLayout"
import { styled /* useTheme */ } from "@mui/material/styles"
import { ThemeProvider, CssBaseline, Box } from "@mui/material"
import { Circles } from "react-loader-spinner";

const AppWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
});

const ContentWrapper = styled(Box)({
  flex: "1 0 auto",
});

const App = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [imageDimensions, setImageDimensions] = useState(null);
  const [posters, setPosters] = useState([]);
  const initialLoadRef = useRef(true);

  const apiver = "api/v3";
  const singleImg = "/image/tt1075747";

  const isSearchable = process.env.REACT_APP_SEARCHABLE === "true";
  //const theme = useTheme()
  //console.log(theme)

  if (!imageDimensions) {
//    console.log("in not imageDimensions");
    return (
      <div>
        <GetSingleImageDim
          imgUrl={`${apiver}${singleImg}`}
          onImageLoaded={(h, w) => {
//            console.log('onImageLoaded called with:', { h, w });
            setImageDimensions({ height: h, width: w })
          }}
        />
       <Circles color="#7D8C91" height={80} width={80} />
      </div>
    );
  }

  return (
    <HelmetProvider>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        {/* <ThemeInspector /> */}
        <Router>
          {!isSearchable && <meta name="robots" content="noindex" />}
          <AppWrapper>
            <CustomNav showSearch={showSearch} setShowSearch={setShowSearch} />
            <ContentWrapper>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <Home
                      showSearch={showSearch}
                      setShowSearch={setShowSearch}
                      imageDimensions={imageDimensions}
                      posters={posters}
                      setPosters={setPosters}
                      initialLoadRef={initialLoadRef}
                    />
                  }
                />
                <Route
                  exact
                  path="/about"
                  element={
                    <PageLayout>
                      <About />
                    </PageLayout>
                  }
                />
                <Route
                  exact
                  path="/contact"
                  element={
                    <PageLayout>
                      <Contact />
                    </PageLayout>
                  }
                />
              </Routes>
            </ContentWrapper>
          </AppWrapper>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App
