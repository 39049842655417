import { Autocomplete, Checkbox, FormControl, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
  select_input: {
    width: "312px",
    margin: "5px 5px 0px 5px",
    [theme.breakpoints.down("1020")]: {
      width: "100%",
    },
    [theme.breakpoints.down("899")]: {
      width: "65%",
    },
    [theme.breakpoints.down("865")]: {
      margin: 5,
    },
  },
  autocomplete_container: {
    width: "100%",
    maxHeight: 72,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "5px",
  },
  customTextField: {
    "& fieldset": { border: "none" },
    "& input": { padding: 0 },
    "& .MuiOutlinedInput-root": {
      width: "100%",
      padding: "4px !important",
      paddingRight: "0px !important",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "12px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "10px",
        border: `5px solid ${theme.palette.background.paper}`,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingRight: "0px !important",
      flexWrap: "nowrap !important",
    },
    "& .MuiAutocomplete-endAdornment": {
      top: 0,
      right: 0,
      position: "relative",
      transform: "none",
      display: "flex",
      alignItems: "center",
    },
  },
}));

const MultiSelectType =  ({ selectedTitleType, onSelectedTitleType, movieTypeOptions, label }) => {
  const classes = useStyles();    
  const selectedMovieTypes = movieTypeOptions.filter((option) =>
    selectedTitleType.includes(option.value)
  );

  return (
    <FormControl
      variant="outlined"
      size="small"
      className={classes.select_input}
    >
      <Autocomplete
        multiple
        id="autocomplete-movieType"
        options={movieTypeOptions}
        disableCloseOnSelect
        value={selectedMovieTypes}
        getOptionLabel={(movie) => movie.name}
        onChange={(_, options) =>
          onSelectedTitleType(options.map((option) => option.value))
        }
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          );
        }}
        className={classes.autocomplete_container}
        ChipProps={{
          sx: { cursor: 'pointer' },
        }}        
        renderInput={(params) => (
          <TextField
            {...params}
            title={label}
            style={{ border: "none !important" }}
            placeholder={label}
            className={classes.customTextField}
          />
        )}
      />
    </FormControl>
  );
}

export default MultiSelectType
