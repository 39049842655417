import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { styled } from "@mui/material/styles";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Bunny from "../assets/ratingbunny_core.jpg";

// Styling using MUI's styled utility
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main, // Set to primary color or desired background color
  color: theme.palette.text.link, // Set to primary color or desired background color
  top: 0, // Aligns it to the top
  width: "100%",
  zIndex: theme.zIndex.appBar, // Ensures it stays above content
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: "32px", // Reduce or adjust this value as needed
  padding: theme.spacing(0), // Adjust the padding, possibly reduce or remove
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    minHeight: "32px",
  },
}));

const CenterBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.link,
  textDecoration: "none",
  fontFamily: theme.typography.fontFamily,
  fontWeight: 700,
  letterSpacing: ".3rem",
  textAlign: "center",
}));

const CustomNav = ({ showSearch, setShowSearch }) => {
  const location = useLocation()
  const isOnSearchPage = location.pathname === "/"

  const handleSearchClick = () => {
    setShowSearch((prev) => !prev); // Simply toggle the filter visibility
  };

  return (
    <StyledAppBar position="fixed">
      <Container maxWidth="xl">
        <StyledToolbar disableGutters>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSearchClick}
            startIcon={<SearchIcon />}
            disabled={!isOnSearchPage}
          />
          <CenterBox sx={{ flexGrow: 1 }}>
            <StyledTitle variant="h6" noWrap component={RouterLink} to={"/"}>
              Rating
            </StyledTitle>

            <Box
              component={RouterLink}
              to="/about"
              sx={{
                display: "inline-block",
                margin: "0 8px",
                textDecoration: "none", // Remove underline from link
              }}
            >
              <Box
                component="img"
                sx={{
                  maxHeight: { xs: 45, md: 50 },
                  maxWidth: { xs: 45, md: 50 },
                  margin: "0 8px",
                }}
                alt="Bunny logo"
                src={Bunny} // Replace with actual Logo variable
              />
            </Box>

            <StyledTitle variant="h6" noWrap component={RouterLink} to={"/"}>
              Bunny
            </StyledTitle>
          </CenterBox>

          <Button
            component={RouterLink}
            to={"/contact"}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<ContactPageIcon/>}
          />          
        </StyledToolbar>
      </Container>
    </StyledAppBar>
  );
};

export default CustomNav;
