import { styled } from "@mui/material/styles"
import Toggle from "react-toggle"
import Typography from "@mui/material/Typography"

const StyledDiv = styled('div')(({ theme }) => ({
  select_input: {
    width: "160px",
    margin: "5px !important",
  },  
}))

const AdultToggle = (props) => {
  const { adult, onSelectAdult } = props

  return (
    <StyledDiv id="adult-selector">
      <Typography id="adult-toggle">NSFW</Typography>
      <Toggle
        id="adult-status"
        defaultChecked={adult}
        onChange={(e) => onSelectAdult(e.target.checked)}
      />
    </StyledDiv>
  )
}

export default AdultToggle
