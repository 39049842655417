import React, { useEffect, useRef } from 'react'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'

const GetSingleImageDim = ({ imgUrl, onImageLoaded }) => {
  const imgRef = useRef(null);

  useEffect(() => {
    const imgElement = imgRef.current;

    if (imgElement) {
      const handleLoad = () => {
//        console.log('Image loaded:', imgElement.src);
        const { height, width } = imgElement.getBoundingClientRect();
        onImageLoaded(height, width);
      };

      if (imgElement.complete) {
        // Image is already loaded (from cache)
        handleLoad();
      } else {
        // Image is still loading
        imgElement.addEventListener('load', handleLoad);
        return () => {
          imgElement.removeEventListener('load', handleLoad);
        };
      }
    }
  }, [imgUrl, onImageLoaded]);


  return (
    <Card
      raised
        sx={{
          position: 'absolute',
          left: '-9999px', // Keeps it off-screen if needed
          width: '230px'
        }}         
    >
      <CardMedia
        component="img"
        ref={imgRef}
        src={imgUrl}
        alt="Image for Dimension Calculation"
      />
    </Card>    
  )
}

export default GetSingleImageDim
