import { withStyles } from '@mui/styles'
import Slider from '@mui/material/Slider'

// Define your custom styles for the slider
const CustomSliderStyles = {
    root: {
        height: 2,
        padding: "8px 0",
    },
    label: {
        color: "blue",
    },
    thumb: {
        width: 12,
        height: 12,
        "&:hover, &.Mui-focusVisible, &.Mui-active, &.focus": {
            boxShadow: "none",
        },
    },
}

const CustomSlider = withStyles(CustomSliderStyles)(Slider)

export default CustomSlider
