import React from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import CustomSlider from "./CustomSliderStyles"
import Typography from "@mui/material/Typography"

const StyledBox = styled(Box)(({ theme }) => ({
    width: "100%",
    margin: "5px",
    boxSizing: "border-box",
    [theme.breakpoints.down('1020')]: {
        width: "100%",
      },
      [theme.breakpoints.down('899')]: {
        width: "65%",
      },
}))

const YearSlider = ({ years, basicStartYear, currentYear, onSelectYear }) => {
    return (
        <StyledBox id="year-selector" data-test-id="yr-selector-box">
            <Typography id="yr-slider">Years ({years[0]}-{years[1]})</Typography>
            <CustomSlider
                value={years}
                min={basicStartYear}
                max={currentYear}
                onChange={onSelectYear}
                valueLabelDisplay="off"
            />
        </StyledBox>
    )
}

export default YearSlider
