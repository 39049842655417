import React, { Fragment } from "react"
import AboutInfo from "../components/AboutInfo"

const About = () => {
  return (
    <Fragment>
      <AboutInfo />
    </Fragment>
  )
}

export default About
