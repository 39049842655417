import React from "react"
import ContactForm from "../components/ContactForm"

const Contact = () => {
  return (
    <React.Fragment>
      <ContactForm />
    </React.Fragment>
  )
}

export default Contact
