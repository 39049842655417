/* eslint-disable no-unused-vars */
// library imports
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useTheme, makeStyles } from "@mui/styles";
import {
  Checkbox,
  Grid,
  Box,
  Container,
  ListItemText,
  Link,
  MenuItem,
  Typography,
  useMediaQuery,
  CardMedia,
  Card,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import InfiniteScroll from "react-infinite-scroll-component";
import "react-toggle/style.css";

// file imports
import ShowFilmsBtn from "./ShowFilmsBtn";
import AdultToggle from "./AdultToggle";
import pkgJson from "../../package.json";
import RatingSlider from "./RatingSlider";
import YearSlider from "./YearSlider";
import VoteSlider from "./VoteSlider";
import DebouncedSelect from "./DebouncedSelect";
import MultiSelectType from "./MultiSelectType";
import { CircleLoader } from "./CircleLoader";
import { Circles } from "react-loader-spinner";

const currentYear = new Date().getFullYear();
const roles = {
  default: {
    genre: ["Action", "Adventure"],
    year: [currentYear - 3, currentYear],
    votes: 50000,
    titleType: ["Movies"],
    isAdult: false,
  },
  gamer: {
    genre: ["Fantasy", "Sci-Fi"],
    year: [2005, 2020],
    titleType: ["videoGame"],
  },
  oldies: {
    genre: ["Drama", "Romance"],
    year: [1945, 1965],
    titleType: ["movie", "tvMovie"],
    isAdult: false,
  },
};

const gridCardStyles = makeStyles((theme) => ({
  filterContainer: {
    height: "280px",
    [theme.breakpoints.down("955")]: {
      height: "320px",
    },
  },
  cardroot: {
    "& .details": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      "&:hover .details": {
        display: "flex",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& .details": {
        display: "flex",
      },
    },
    "&:hover .poster": {
      filter: "blur(2px)",
    },
  },
  scrollable: {
    overflowY: "visible",
    paddingTop: "0px",
    marginTop: "0px",
  },
}));

const pkgBaseUrl = pkgJson.proxy;
axios.defaults.baseURL = pkgBaseUrl;
const apiver = "api/v3";
const basicStartYear = 1895;
const defaultRoleYear = 1990;
const yearValues = [defaultRoleYear, currentYear];

// Json Data
const genreList = [
  "Action",
  "Adult",
  "Adventure",
  "Animation",
  "Biography",
  "Comedy",
  "Crime",
  "Documentary",
  "Drama",
  "Family",
  "Fantasy",
  "Film-Noir",
  "Game-Show",
  "History",
  "Horror",
  "Music",
  "Musical",
  "Mystery",
  "News",
  "Reality-TV",
  "Romance",
  "Sci-Fi",
  "Short",
  "Sport",
  "Talk-Show",
  "Thriller",
  "War",
  "Western",
];

const movieList = [
  "movie",
  "short",
  "tvEpisode",
  "tvMiniSeries",
  "tvMovie",
  "tvSeries",
  "tvShort",
  "tvSpecial",
  "video",
  "videoGame",
];

const autotypes = [
  { name: "Title", value: "title" },
  { name: "Actor", value: "name" },
];

const genres = genreList.map((m) => ({ name: m, value: m }));
const movie = movieList.map((m) => ({ name: m, value: m }));

const typeGrouping = {
  Movies: ["movie"],
  Shorts: ["short"],
  "TV Shows": ["tvSeries", "tvMiniSeries", "tvEpisode", "tvMovie", "tvSpecial", "tvShort"],
  "Video Games": ["videoGame"],
  "Other Videos": ["video"],
};

const movieTypeOptions = [
  { name: "Movies", value: "Movies" },
  { name: "Shorts", value: "Shorts" },
  { name: "TV Shows", value: "TV Shows" },
  { name: "Video Games", value: "Video Games" },
  { name: "Other Videos", value: "Other Videos" },
];

// Utility Functions
const getWindowWidth = () => {
  return window.innerWidth;
};

const getWindowHeight = () => {
  return window.innerHeight;
};

const valuetext = (value) => {
  return `${value}`;
};

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const capitalize = (str, lowerRest = false) => {
  const capitalizeWord = (word, shouldCapitalize) =>
    shouldCapitalize
      ? word.charAt(0).toUpperCase() +
        (lowerRest ? word.slice(1).toLowerCase() : word.slice(1))
      : lowerRest
      ? word.toLowerCase()
      : word;

  const words = str.split(" ");

  const capitalizedString = words
    .map((word, index) =>
      // Capitalize the first and last words, others based on lowerRest
      capitalizeWord(word, index === 0 || index === words.length - 1)
    )
    .join(" ");

  //console.log("got back " + capitalizedString);
  return encodeURIComponent(capitalizedString);
};

const renameKey = (origKey, newKey, objList) => {
  const newList = objList.map((obj) => {
    obj[newKey] = obj[origKey];
    delete obj[origKey];
    return obj;
  });
  return newList;
};

const addUrl = (objList) => {
  const newList = objList.map((obj) => {
    const newUrl = `${apiver}/image/${obj.id}`;
    return { ...obj, url: newUrl };
  });
  return newList;
};

const getData = (path, outerData) => {
  //  console.log("getData with query " + outerData.genre)
  return axios.post(`${apiver + path}`, JSON.stringify(outerData));
};

const Search = ({
  imageDimensions,
  showSearch,
  setShowSearch,
  posters,
  setPosters,
  initialLoadRef, // Consider removing this prop if not necessary
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const classes = gridCardStyles({ isMobile, isTablet });

  // All states
  const [autotype, setAutotype] = useState("title");
  const [rating, setRating] = useState("7");
  const [votes, setVotes] = useState("50000");
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [titleSuggestions, setTitleSuggestions] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState(["Action", "Adventure"]);
  const [textValue, setTextValue] = useState("");
  const [selectedTitleType, setSelectedTitleType] = useState(["movie"]);
  const [adult, setAdult] = useState(false);
  const [years, setYears] = useState(yearValues);
  const [page, setPage] = useState(1);
  const [showDisabled, setShowDisabled] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [lastTitleSelection, setLastTitleSelection] = useState({
    primaryTitle: "",
    isModified: false,
    selected: false,
  });
  const [lastQuery, setLastQuery] = useState({
    ...roles["default"],
    titleType: roles['default'].titleType.flatMap((group) => typeGrouping[group]),
  });
  const [isAppending, setIsAppending] = useState(false);
  const [width, setWidth] = useState(document.documentElement.clientWidth);
  const [searching, setSearching] = useState(false);
  const [selectedTypeGroups, setSelectedTypeGroups] = useState(roles['default'].titleType);

  // All refs
  const lastQueryRef = useRef(lastQuery);
  const hasMoreDataRef = useRef(hasMoreData);
  const isMountedRef = useRef(true);
  const isAppendingRef = useRef(isAppending);
  // Local ref to prevent multiple initial fetches
  const hasFetchedInitialData = useRef(false);

  // For building the API path while infinite scrolling
  const makePath = (pg, rtng, cardObj) => {
    const windowWidth = getWindowWidth();
    const windowHeight = getWindowHeight();
    const cardWidth = cardObj.width;
    const cardHeight = cardObj.height;
    return (
      "/" +
      autotype +
      "/" +
      pg +
      "/" +
      rtng +
      "?ws=" +
      windowWidth +
      "&wh=" +
      windowHeight +
      "&cs=" +
      cardWidth +
      "&ch=" +
      cardHeight +
      "&off=0"
    );
  };

  /* All Handler functions */

  // For Processing the API response
  const handleResponse = (res) => {
    const remainingCount = parseInt(res.headers["x-remaining-count"], 10);
//    console.log(`Response received. Data length: ${res.data.length}, Remaining Count: ${remainingCount}`);
    
    if (res.data.length === 0 && remainingCount === 0) {
      setHasMoreData(false);
//      console.log("No more data. Setting hasMoreData to false.");
      if (page === 1) setPosters([]);
    } else if (res.data.length !== 0 && remainingCount === 0) {
      processData(res.data);
      setHasMoreData(false);
//      console.log("Fetched data but no remaining count. Setting hasMoreData to false.");
    } else {
      processData(res.data);
//      console.log("Fetched data and there is remaining count. hasMoreData remains true.");
    }
  };
  

  // Note: Depending on browser...   setWidth(window.innerWidth)
  const handleResize = () => setWidth(document.documentElement.clientWidth);

  const handleShowFilmsClick = (e) => {
    setShowSearch(false);
    onSubmit(e);
  };

  const triggerDataFetch = () => {
    if (!isLoading && hasMoreData) {
      setPage((prevPage) => prevPage + 1);
//      console.log(`Triggering data fetch for page ${page + 1}`);
//    } else {
//      console.log(`Cannot fetch data. isLoading: ${isLoading}, hasMoreData: ${hasMoreData}`);
    }
  };
  

  // Fetching data for the page Using APIs
  const fetchDataForPage = async (page, isFirstPage = false) => {
    if (isLoading) return;

    setIsLoading(true);
    setIsAppending(!isFirstPage);
    isAppendingRef.current = !isFirstPage
//    console.log(`Fetching data for page ${page}, isFirstPage: ${isFirstPage}, isAppending: ${!isFirstPage}`);

    try {
      const prevRating = rating;

      const path = makePath(page, prevRating, imageDimensions);
      const dataQuery = lastQueryRef.current;
      const stQuery = (() => {
        const { query, searchType, ...rest } = dataQuery;

        if (autotype === "title") {
          if (lastTitleSelection.primaryTitle !== "") {
            return {
              ...dataQuery,
              searchType: lastTitleSelection.isModified ? "regex" : "exact",
              query: lastTitleSelection.primaryTitle,
            };
          } else {
            return { ...rest };
          }
        } else if (autotype === "name") {
          return {
            ...dataQuery,
            query: textValue,
          };
        } else {
          return query === "" ? { ...rest } : dataQuery;
        }
      })();

      setLastTitleSelection({
        primaryTitle: "",
        isModified: false,
        selected: false,
      });

      const response = await getData(path, stQuery);
      handleResponse(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
      setTextValue("");
      if (isFirstPage) {
        setSearching(false)     
//        console.log("Stopped searching after initial fetch.");
      }
//      console.log(`Fetch for page ${page} completed. isLoading set to false.`);
    }
  };

  useEffect(() => {
    // Set up the resize event listener
    window.addEventListener("resize", handleResize);

    // Mark component as mounted
    isMountedRef.current = true;

    // Cleanup function
    return () => {
      // Reset padding to body

      // Remove the resize event listener
      window.removeEventListener("resize", handleResize);

      // Set isMountedRef to false
      isMountedRef.current = false;
    };
  }, []);

  /* All useEffects */
  useEffect(() => {
    if (page > 1) {
      // To avoid fetching on initial render
      fetchDataForPage(page);
    }
  }, [page]);

  useEffect(() => {
    if (!hasFetchedInitialData.current && imageDimensions && !showSearch) {
      hasFetchedInitialData.current = true;
  
      const loadInitialData = async () => {
        setSearching(true);
        try {
          setYears(roles["default"].year);
          setLastQuery({
            ...roles["default"],
            titleType: roles["default"].titleType.flatMap((group) => typeGrouping[group]),
          });
          lastQueryRef.current = {
            ...roles["default"],
            titleType: roles["default"].titleType.flatMap((group) => typeGrouping[group]),
          };
  
          // Reset posters for a new search
          setPosters([]);
  
          // Trigger data fetch for the first page
          await fetchDataForPage(1, true);
        } catch (error) {
          console.error("Error during initial load: ", error);
        } finally {
          setSearching(false);
        }
      };
  
      loadInitialData();
    }
  }, [imageDimensions, showSearch]);
  

  useEffect(() => {
    lastQueryRef.current = lastQuery;
  }, [lastQuery]);

  useEffect(() => {
    hasMoreDataRef.current = hasMoreData;
  }, [hasMoreData]);

  // After setting 'searching' to true or false

  useEffect(() => {
    isAppendingRef.current = isAppending
//    console.log(`isAppending state changed to: ${isAppending}`);
  }, [isAppending]);  

  const autoTypeOptions = () => {
    return autotypes.map((k, index) => (
      <MenuItem key={index} value={k.value}>
        {k.name}
      </MenuItem>
    ));
  };

  const processData = (data) => {
    if (data.length !== 0) {
//      console.log(`Processing ${data.length} new posters. isAppending: ${isAppendingRef.current}`);
      getPosters(addUrl(renameKey("_id", "id", data)));
    } else {
      if (!isAppendingRef.current) {
//        console.log("No data and not appending. Clearing posters.");
        setPosters([]);
      }
      // Update isAppending if needed
      setIsAppending(false);
      isAppendingRef.current = false
    }
  };

  const getPosters = async (posterData) => {
    const isAppendingCurrent = isAppendingRef.current;
//    console.log(`Appending new posters: ${isAppendingCurrent}`);

    setPosters((prevPosters) => {
      const combinedPosters = isAppendingCurrent
        ? [...prevPosters, ...posterData]
        : posterData;
  
      const uniquePostersMap = new Map();
      combinedPosters.forEach((poster) => {
        uniquePostersMap.set(poster.id, poster);
      });
      const uniquePosters = Array.from(uniquePostersMap.values());
//      console.log(`Total posters after update: ${uniquePosters.length}`);
      return uniquePosters;
    });
  };
  
  const fetchAutoValue = (val) => {
      const prevRating = rating
      const path =
        autotype === "title"
          ? `/autotitle/${val}/${prevRating}`
          : `/autoname/${val}/${prevRating}`;
      const getQuery = lastQueryRef.current;
      getData(path, getQuery).then((res) => {
        if (autotype === "title") {
          setTitleSuggestions(res.data);
        } else
         setNameSuggestions(res.data);
      });
  };

  const onTitleActorFetchRequested = (value) => {
    fetchAutoValue(escapeRegexCharacters(capitalize(value, true)));
  };

  const titleInputProps = {
    placeholder: `Type ${autotype}`,
    value: textValue,
  };

  const nameInputProps = {
    placeholder: `Type actor`,
    value: textValue,
  };

  const onSelectAdult = (mtPicked) => {
    setAdult(mtPicked);
    setLastQuery((prevState) => ({
      ...prevState,
      isAdult: mtPicked,
    }));
  };

  const onSelectAutotype = (autoPicked) => {
    const shouldDisable = autoPicked === "name";
    setAutotype(autoPicked);
    setShowDisabled(shouldDisable);
    setPage(1);
    setTextValue("");
  };


  const onSelectedTitleType = (selectedGroups) => {
    setSelectedTypeGroups(selectedGroups);
  
    // Map the selected groups to the original types
    const selectedTypes = selectedGroups.flatMap((group) => typeGrouping[group]);
  
    // Update the query state
    if (selectedTypes.length > 0) {
      setLastQuery((prevState) => ({
        ...prevState,
        titleType: selectedTypes,
      }));
    } else {
      // Remove titleType from the query if none selected
      setLastQuery((prevState) => {
        const { titleType, ...rest } = prevState;
        return rest;
      });
    }
  };  

  const onSelectedGenre = (genre) => {
    setIsAppending(false);
    const newGenres =
      genre[0] === "Genre"
        ? genre.slice(1)
        : genre.length === 0
        ? ["Genre"]
        : genre;
    setSelectedGenres(newGenres);
    if (newGenres[0] !== "Genre") {
      setLastQuery((prevState) => ({
        ...prevState,
        genre: newGenres,
      }));
    } else {
      setLastQuery((prevState) => {
        const { genre, ...rest } = prevState;
        return rest;
      });
    }
  };

  const onSelectYear = (event, yrPicked) => {
//    console.log('onSelectYear called');
    setIsAppending(false);
    setYears(yrPicked);
    setLastQuery((prevState) => ({ ...prevState, year: yrPicked }));
  };

  const onChangeRating = (event, ratingPicked) => {
    setIsAppending(false);
    setRating(ratingPicked);
  };

  const onChangeVotes = (event, votesPicked) => {
    setIsAppending(false);
    setVotes(votesPicked);
    setLastQuery((prevState) => ({
      ...prevState,
      votes: votesPicked,
    }));
  };

  // Modify the onSubmit to set 'searching' to true and reset the fetch state
  const onSubmit = (e) => {
    e.preventDefault();
    const canQuery = autotype === "title" ? years.length > 0 : years.length > 0;
    if (canQuery) {
      setSearching(true)
      setPage(1);
      setHasMoreData(true);

      const currentQuery = {
        genre: selectedGenres,
        year: years,
        votes: votes,
        titleType: lastQuery.titleType,
        isAdult: adult,
      };

      setLastQuery(currentQuery);
      lastQueryRef.current = currentQuery;

      // When a new search is submitted, reset the posters
      setPosters([]);

      // Trigger initial fetch
      fetchDataForPage(1, true);
    }
  };

  const RenderPosters = () => {
    if (posters.length > 0) {
      return (
        <Grid item container direction="row" spacing={3} data-test-id="toprendpost">
          {posters.map((poster) => (
            <Grid
              key={poster.id}
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              className={classes.cardroot}
            >
              <Box
                data-test-id="box grid"
                sx={{
                  position: "relative",
                  overflowY: "hidden",
                  width: "100%",
                  paddingBottom: "150%",
                  height: {
                    xs: "200px", // Height for extra-small screens
                    sm: "250px", // Height for small screens
                    md: "300px", // Height for medium screens
                    lg: "300px", // Height for large screens
                  },
                }}
              >
                <Link
                  underline="hover"
                  href={"https://imdb.com/title/" + poster.id}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Card
                    raised
                    key={poster.originalTitle}
                    data-test-id="cardraised"
                    style={{ position: "relative" }}
                  >
                    <CardMedia
                      width="100%"
                      component="img"
                      sx={{ objectFit: "contain" }}
                      src={`${poster.url}`}
                      alt={poster.originalTitle}
                      className="poster"
                    />
                    <Box
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                        display: "none",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "fit-content",
                        height: "auto",
                        top: 5,
                        right: 5,
                        padding: "4px 8px 4px 8px",
                        position: "absolute",
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                      className="details"
                    >
                      {poster.averageRating}
                      <StarIcon fontSize="10px" />
                    </Box>
                    <Box
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                        display: "none",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "fit-content",
                        height: "auto",
                        bottom: 3,
                        left: 5,
                        padding: "5px",
                        position: "absolute",
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                      className="details"
                    >
                      {poster.genresList[0]}
                    </Box>
                  </Card>
                </Link>
              </Box>
              <Box>
                <Typography variant="subtitle2">
                  <Link
                    underline="hover"
                    href={"https://imdb.com/title/" + poster.id}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {poster.originalTitle.slice(0, 20)}
                  </Link>
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "theme.text.primary" }}
                >
                  {poster.startYear}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      );
    }
  };

  const renderNoData = () => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SentimentDissatisfiedIcon color="disabled" fontSize="large" />
        <Typography variant="h6" gutterBottom>
          No Results Found
        </Typography>
        <Typography variant="subtitle1">
          Try adjusting your filters or new search.
        </Typography>
      </Box>
    );
  };

  const filtersComponents = [
    <DebouncedSelect
      fetchOptions={onTitleActorFetchRequested}
      autotype={autotype}
      onSelectAutotype={onSelectAutotype}
      autoTypeOptions={autoTypeOptions}
      namesuggestions={nameSuggestions}
      titlesuggestions={titleSuggestions}
      titleInputProps={titleInputProps}
      nameInputProps={nameInputProps}
      setLastTitleSelection={setLastTitleSelection}
      setIsAppending={setIsAppending}
      setShowDisabled={setShowDisabled}
      lastTitleSelection={lastTitleSelection}
      textValue={textValue}
      setTextValue={setTextValue}
      setLastQuery={setLastQuery}
    />,
    <MultiSelectType
      selectedTitleType={selectedTypeGroups}
      onSelectedTitleType={onSelectedTitleType}
      movieTypeOptions={movieTypeOptions}
      label="Movie Type"
    />,
    <MultiSelectType
      selectedTitleType={selectedGenres}
      onSelectedTitleType={onSelectedGenre}
      movieTypeOptions={genres}
      label="Genre"
    />,
    <RatingSlider
      valueText={valuetext}
      curRating={rating}
      ratingChange={onChangeRating}
    />,
    <YearSlider
      years={years}
      basicStartYear={basicStartYear}
      currentYear={currentYear}
      onSelectYear={onSelectYear}
      valuetext={valuetext}
    />,
    <VoteSlider
      votes={votes}
      valuetext={valuetext}
      onChangeVotes={onChangeVotes}
    />,
    <AdultToggle adult={adult} onSelectAdult={onSelectAdult} />,
    <ShowFilmsBtn onClick={handleShowFilmsClick} disabled={showDisabled} />,
  ];

  // For Rendering the Mobile Filters
  const RenderMobileFilters = () => {
    const firstGroup = filtersComponents
      .slice(0, 3)
      .map((Component, index) => (
        <React.Fragment key={index}>{Component}</React.Fragment>
      ));

    const secondGroup = filtersComponents
      .slice(3, 6)
      .map((Component, index) => (
        <React.Fragment key={index + 3}>{Component}</React.Fragment>
      ));

    const thirdGroup = filtersComponents
      .slice(6, 8)
      .map((Component, index) => (
        <React.Fragment key={index + 3}>{Component}</React.Fragment>
      ));

    return (
      <>
        <Grid item xs={6} sm={4}>
          {firstGroup}
        </Grid>
        <Grid item xs={6} sm={4}>
          {secondGroup}
        </Grid>
        <Grid item xs={12} sm={4}>
          {thirdGroup}
        </Grid>
      </>
    );
  };

  // For Rendering the Desktop Filters
  const RenderDesktopFilters = () => {
    const titleDrop = filtersComponents[0];
    const movieDrop = filtersComponents[1];
    const actionDrop = filtersComponents[2];
    const imdbSlider = filtersComponents[3];
    const yearsSlider = filtersComponents[4];
    const votesSlider = filtersComponents[5];
    const adultCheck = filtersComponents[6];
    const submitBtn = filtersComponents[7];

    return (
      <Grid
        container
        spacing={1}
        className={classes.filterContainer}
        justifyContent={"space-between"}
      >
        <Grid item xs={3}>
          <Grid container direction="column" spacing={2} paddingBottom={5}>
            <Grid item width={"100%"}>
              {titleDrop}
            </Grid>
            <Grid item width={"100%"}>
              {movieDrop}
            </Grid>
            <Grid item width={"100%"}>
              {actionDrop}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item>{imdbSlider}</Grid>
            <Grid item>{yearsSlider}</Grid>
            <Grid item>{votesSlider}</Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item>{adultCheck}</Grid>
            <Grid item>{submitBtn}</Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {searching && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
        <Typography variant="h4" color="white">
            Loading...
          </Typography>
          <Circles color="#7D8C91" height={80} width={80} />
        </div>
      )}
      <Container
        maxWidth={false}
        disableGutters
        data-test-id="maxwidth"
        sx={{
          overflow: "hidden",
          marginTop: "62px",
          paddingLeft: "4px",
        }}
      >
        {/* Note: combined view */}
          <Box
            id="scrollableDiv"
            data-test-id="top box"
            sx={{
              overflowY: "auto",
              position: "relative",
              height: "calc(100vh - 62px)",
              width: "100%",
            }}
          >
            {showSearch && (
              <Grid
                data-test-id="popupgrid"
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  position: "fixed",
                  top: "60px",
                  width: "100%",
                  zIndex: 1000,
                  backgroundColor: "#fff",
                }}
              >
                {width < theme.breakpoints.values.md ? RenderMobileFilters() : RenderDesktopFilters() }
              </Grid>
            )}
            <Box maxWidth="xl">
              <InfiniteScroll
                dataLength={posters.length}
                next={triggerDataFetch}
                hasMore={hasMoreData}
                loader={isLoading ? <CircleLoader /> : null}
                endMessage={
                  !hasMoreData && (
                    <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                      <Typography variant="h6">
                        <b>No more results</b>
                      </Typography>
                      {/* Optional: Add a graphic or icon here */}
                      <SentimentDissatisfiedIcon color="disabled" fontSize="large" />
                    </Box>
                  )
                }
                scrollableTarget="scrollableDiv"
              >
                {posters.length ? (
                  <Grid container direction="row">
                    {RenderPosters()}
                  </Grid>
                ) : (
                  <Grid height="100%" direction="row" container>
                    {renderNoData()}
                  </Grid>
                )}
              </InfiniteScroll>
            </Box>
          </Box>
      </Container>
    </>
  );
};

export default Search;
