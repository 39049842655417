import React from "react"
import Search from "../components/Search"
import { Box } from "@mui/material"

const Home = ({ showSearch, setShowSearch, imageDimensions, posters, setPosters, initialLoadRef }) => {
  const hasValidImageDimensions =
  imageDimensions &&
  typeof imageDimensions.height === 'number' &&
  typeof imageDimensions.width === 'number' &&
  imageDimensions.height !== 0 &&
  imageDimensions.width !== 0;

//  console.log("in Home ", imageDimensions)
  return (
    <Box
      sx={{
        minHeight: "76vh",
        maxHeight: "82vh",
      }}
    >
      {hasValidImageDimensions ? (
        <Search
          imageDimensions={imageDimensions}
          showSearch={showSearch}
          setShowSearch={setShowSearch}
          posters={posters}
          setPosters={setPosters}
          initialLoadRef={initialLoadRef}
        />
      ): (
        <p>Not rendering Search component</p>
      )}
    </Box>
  )
}

export default Home
