import axios from "axios"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import { useForm, Controller } from "react-hook-form"
import { ToastContainer, toast } from "react-toastify"
import { styled } from '@mui/material/styles'
import "react-toastify/dist/ReactToastify.min.css"

const RedSpan = styled('span')(({ theme }) => ({
    color: 'red',
  }))

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange', // to update form state on every change
    defaultValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
  })

  const apiver = 'api/v3'

  const toastifySuccess = () => {
    toast("Form sent!", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    })
  }

  const sendEmail = (name, email, subject, message) => {
    const path = "addMsg"

    const outerData = {
      name: name,
      email: email,
      subject: subject,
      msg: message,
    }

    axios({
      method: "post",
      url: `${apiver}/${path}`,
      data: outerData,
      headers: {
        "Content-Type": "application/json",
      },
    }).then(
      (res) => {
        if (res.data.length !== 0) {
          console.log(res.data)
          toastifySuccess()
        } else {
          console.log("Empty data returned")
        }
      },
      (error) => {
        console.log("got " + error)
      }
    )
  }

  const onSubmit = async (data) => {
    // Destructure data object
    const { name, email, subject, message } = data
    console.log(
      "sending " + name + ", " + email + ", " + subject + ", " + message
    )
    sendEmail(name, email, subject, message)
  }

  return (
    <section>
      <Container maxWidth="sm">
        <Box pt={8} pb={10}>
          <Box mb={6} textAlign="center">
            <Typography variant="h4" component="h2" gutterBottom={true}>
              Contact Me
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              paragraph={true}
            >
              Thank you and I will respond as soon as possible.
            </Typography>
          </Box>

          <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      type="text"
                      variant="outlined"
                      required
                      fullWidth
                      onChange={onChange}
                      value={value}
                      id="name"
                      label={"Name"}
                      placeholder="Name"
                    />
                  )}
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Please enter your name",
                    },
                    maxLength: {
                      value: 30,
                      message: "Please use 30 characters or less",
                    },
                  })}
                  ref={null}
                />
                {errors.name && (
                  <RedSpan className="errorMessage">{errors.name.message}</RedSpan>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      type="email"
                      variant="outlined"
                      required
                      fullWidth
                      onChange={onChange}
                      value={value}
                      id="email"
                      label={"Email"}
                      placeholder="Email address"
                    />
                  )}
                  {...register("email", {
                    required: true,
                    pattern:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                  ref={null}
                />
                {errors.email && (
                  <RedSpan className="errorMessage">
                    Please enter a valid email address
                  </RedSpan>
                )}
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="subject"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      type="text"
                      variant="outlined"
                      required
                      fullWidth
                      onChange={onChange}
                      value={value}
                      id="subject"
                      label={"Subject"}
                      placeholder="Subject"
                    />
                  )}
                  {...register("subject", {
                    required: {
                      value: true,
                      message: "Please enter a subject",
                    },
                    maxLength: {
                      value: 75,
                      message: "Subject cannot exceed 75 characters",
                    },
                  })}
                  ref={null}
                />
                {errors.subject && (
                  <RedSpan className="errorMessage">{errors.subject.message}</RedSpan>
                )}
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="message"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      variant="outlined"
                      type="text"
                      required
                      multiline
                      fullWidth
                      minRows={5}
                      onChange={onChange}
                      value={value}
                      id="message"
                      label={"Message"}
                      placeholder="Message"
                    />
                  )}
                  {...register("message", {
                    required: true,
                  })}
                  ref={null}
                />
                {errors.message && (
                  <RedSpan className="errorMessage">Please enter a message</RedSpan>
                )}
              </Grid>
            </Grid>
            <Box my={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!isValid} // Disable button if form is invalid
              >
                Submit
              </Button>
            </Box>
          </form>

          <ToastContainer />
        </Box>
      </Container>
    </section>
  )
}

export default ContactForm
