import { styled } from "@mui/material/styles"
import CircularProgress from "@mui/material/CircularProgress"

const Root = styled("div")(({ theme, customStyles }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "15px",
  marginBottom: "10px",
  ...customStyles,
}))

export const CircleLoader = ({ customStyles }) => {
  return (
    <Root customStyles={customStyles}>
      <CircularProgress />
    </Root>
  )
}
