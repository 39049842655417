import React from 'react'
import { styled } from "@mui/material/styles"
import Button from '@mui/material/Button'

const StyledButton = styled(Button)(({ theme }) => ({
    width: '160px',
    margin: '5px !important',
}))

const ShowFilms = ({ onClick, disabled }) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      size="small"
      variant="contained"
      color="primary"
    >
      Show Films
    </StyledButton>
  )
}

export default ShowFilms
