import React from "react";
import { styled } from "@mui/material/styles"

const StyledDiv = styled('div')(({ theme }) => ({
        flex: '1 0 auto',
        minHeight: '75vh',
        maxHeight: '84vh',
        overflowY: 'auto',
}))

const PageLayout = ({ children }) => {
    return (
        <StyledDiv>
            {children}
        </StyledDiv>
    )
}

export default PageLayout